import React, { Component } from "react";
import Konva from "konva";
import { render } from "react-dom";
import Pindrop from '../images/pindrop.png';
// import PinNav from '../images/pin-nav.png';
import closeButton from '../images/close.png';
import PostData from './post-data';
import '../styles/styles.scss';
import setting from './setting';
import WPAPI from 'wpapi';
import {isMobile} from 'react-device-detect';
import Draggable from 'react-draggable';
import ReactTooltip from 'react-tooltip'
var poiEditor;

class BoxText extends Component{

	constructor(props) {
	    super(props);
	    this.state = {
	      text: props.text,
	      currentStage: props.currentStage,
	      id: props.id
	    };
	    this.handleChange = this.handleChange.bind(this);
	    this.removeLayer = this.removeLayer.bind(this);
	    this.dropPinIos = this.dropPinIos.bind(this);
	    this.onStartIos = this.onStartIos.bind(this);
	}
	removeLayer(e){
		const bodyClass = document.body.classList;
  		bodyClass.add('loading');

		this.props.onRemoveLayer(this.props.id);
		e.preventDefault();
	}

	handleChange(e){
		this.setState({newText: e.target.value});
		this.props.onChangeText(e.target.value, this.props.id);
	}

	dropPinIos(e, data){
		// this.setState({text: '', isEditAble: ! this.state.editAble});
		this.props.dropPinIos(e, data, this.props.id);
		e.preventDefault();
	}

	onStartIos(e, data){
		this.props.onStartIos(e, data);
	}

	componentDidUpdate(){
		
		this.state.new = false;
	}

	render(){
		const PinNav = Pindrop;
		const dragAbleIos = isMobile ? (
	 		<Draggable
		        position={{x:0,y:0}}
		        onStart={this.onStartIos}
		        onStop={this.dropPinIos}>
		        <div>
		          <img src={PinNav} />
		        </div>
		    </Draggable>
	 	) : <span className="button pin-drop-btn" id="poi-pindrop" draggable="true"><img src={PinNav} /></span>;
	 	const classList = this.props.editAble ? "box clearfix added" : "box clearfix";
	 	
	 	// if(this.state.currentStage != this.props.currentStage){
	 	// 	this.state.currentStage = this.props.currentStage;
	 	// 	this.state.text = this.props.text;
	 	// }

	 	let newText;
	 	if( this.state.newText !== undefined ){
	 		newText = this.state.newText;
	 		delete this.state.newText;
	 	}else{
	 		newText = this.props.text;
	 	}

		return (
			<div id={`box_${this.props.currentStage}_${this.props.id}`} className={classList}>
				<div className="pin-drop">
					{dragAbleIos}
				</div>
				<div className="box-content clearfix">
					<label>POI {this.props.id}</label>
					<textarea disabled={! this.props.editAble} value={newText} onChange={this.handleChange}></textarea>
				</div>
				<a className="delete" onClick={this.removeLayer}>X</a>
			</div>
		);
	}
}
class Project extends Component {
	constructor(props){
		super(props);

		this.state = {
			currentStage: 0,
			loading:true,
			size:0
		}

		this.autoSave = 0;
		this.isChanging = false;
		this.scale = 1;

		// bind this
		this.addNewPindrop = this.addNewPindrop.bind(this);
		this.changeBackground = this.changeBackground.bind(this);
		this.insertBackground = this.insertBackground.bind(this);
		this.deleteBackground = this.deleteBackground.bind(this);
		this.dropPin = this.dropPin.bind(this);
		this.dropPinIos = this.dropPinIos.bind(this);
		this.onTouchMove = this.onTouchMove.bind(this);
		this.onStartIos = this.onStartIos.bind(this);
		this.dropOver = this.dropOver.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.save = this.save.bind(this);
		this.order = this.order.bind(this);
		this.onChangeText = this.onChangeText.bind(this);
		this.removeLayer = this.removeLayer.bind(this);
		this.sendLinkToClient = this.sendLinkToClient.bind(this);
	}

	onTouchMove(e){
		e.preventDefault();
	}

	componentWillMount(){
		let token = sessionStorage.getItem('edtkey');
		this.post_id = (this.props.match.params.id) ? parseInt(this.props.match.params.id) : 0;
		document.body.classList.add('project');

		if( ! token ){
			let params = new URLSearchParams(this.props.location.search);
			token = params.get('token');
		}

		this.token = token;
	
		const _this = this;

		if( token && this.post_id && Number.isInteger(this.post_id) ){
			this.wp = new WPAPI({ endpoint: setting.api_url });

			this.wp.setHeaders('Authorization', 'Bearer '+ token);

			this.wp.posts().id(this.post_id).get().then(function(res){
				if( res.editor_settings && res.editor_settings.length ){
					document.getElementById('project-title').innerHTML = res.title.rendered;
					res.editor_settings.map(function(stage){
						if( ! stage.layers ){
							stage.layers = [];
						}


						// for test
						// if( setting.is_test ){
						// 	stage.image = 'http://html.local/poi-editor/src/images/gelsted.jpg';
						// }

						return stage;
					});

					_this.setState({
						currentStage:0,
						stages: res.editor_settings,
						loading:false,
						size: res.editor_settings.length
					});
				}else{
					this.setState({
						currentStage:0,
						loading:false,
						size:0
					});
				}
			});
		}else{
			this.setState({
				currentStage:0,
				loading:false,
				size:0
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
	    window.addEventListener('beforeunload', this.saveOnClose.bind(this));
	}

	resize(){
		if( ! this.wrapper ){
			this.wrapper = document.getElementById('poi-editor').children[0];
		}
		if( ! this.wrapper ) return;

		const wEditor = document.getElementById('poi-wrapper').clientWidth;

		if( wEditor < 1000  ){
			// this.currentScreenSize = window.innerWidth;
			this.scale = wEditor > 1000 ? 1 : wEditor/1000;
			// this.wrapper.style.transform = "scale("+ scale +","+ scale +")";
			poiEditor.scale({
				x: this.scale,
				y: this.scale
			});

			poiEditor.draw();

			this.wrapper.style.height = (poiEditor.height() * this.scale) +'px';
			this.wrapper.style.width = (poiEditor.width() * this.scale) +'px';
			// document.getElementById('poi-wrapper').style.transform= "scale("+ scale +","+ scale +")";
			// setTimeout(function(){
			// 	document.getElementById('poi-wrapper').style.transform= "none";
			// },1000);
		}else if( this.scale && this.wrapper ){
			this.wrapper.style.height = (poiEditor.height() * this.scale) +'px';
			this.wrapper.style.width = (poiEditor.width() * this.scale) +'px';
		}
	}

	componentDidMount(is_loaded){
		if( ! this.state.stages ){
			return "";
		}

		this.wrapClass = document.getElementById('poi-wrapper').classList;

		this.wrapClass.add('loading');

		// const _POI_Editor = document.getElementById('poi-editor');
		const stageObj = this.state.stages[this.state.currentStage];

		if( ! poiEditor ){
			poiEditor = new Konva.Stage({
		    	container: 'poi-editor',
		        width: 1000,
		        height: 600
		    });


		    poiEditor.on('contextmenu',function(e){
		    	e.evt.preventDefault();
		    });
		}

	    let imageObj = new window.Image();
	    imageObj.setAttribute('crossOrigin', 'anonymous');
	    let layer = new Konva.Layer({
			x:0,
  			y:0
		});

		let _this = this;
		// layer.setZIndex(2);
	    imageObj.onload = function() {
	    	if( ! imageObj.width ){
	    		alert("No 'Access-Control-Allow-Origin' header is present on the requested resource");
	    	}
			let image = new Konva.Image({
				x: 0,
				y: 0,
				width:1000,
				height: imageObj.height * 1000/imageObj.width,
				image: imageObj
			});

			poiEditor.setHeight(imageObj.height * 1000/imageObj.width);

			// update resize
			_this.resize();

			// poiEditor.setWidth(imageObj.width);

			// // add the shape to the layer
			layer.add(image);

			// // add the layer to the stage
			poiEditor.add(layer);

			stageObj.layers.map((layerObj,id) => {

				_this.drawLayer(layerObj, id);

			});
			// remove loading
			_this.wrapClass.remove('loading');
	    };

	    imageObj.src = stageObj.image;

	    setTimeout(function(){
	    	if(! imageObj.width){
	    		alert("No 'Access-Control-Allow-Origin' header is present on the requested resource");
		    	_this.wrapClass.remove('loading');
		    }
	    },20000);


	    document.getElementById('poi-wrapper').addEventListener('touchmove', this.dropOver, { passive: false });
	    document.getElementById('poi-right-sidebar').addEventListener('touchmove', this.dropOver, { passive: false });
  	}

  	componentWillUnmount(){
  		if( this.autoSave ){
  			clearTimeout(this.autoSave);
  		}

  		window.removeEventListener("resize", this.resize.bind(this));
  		window.removeEventListener('beforeunload', this.saveOnClose.bind(this));
  		document.getElementById('poi-wrapper').removeEventListener('touchmove', this.dropOver, { passive: false });
  		document.getElementById('poi-right-sidebar').removeEventListener('touchmove', this.dropOver, { passive: false });
  		// delete editor
  		delete window.poiEditor;
  	}

  	saveOnClose(e){
  		if( this.isChanging ){
  			const that = this;
  			this.save(()=>{
  				that.wrapClass.remove('loading');
  				alert('Saved, You can close your tab now!');
  			});
	  		e.preventDefault();
		 	// Chrome requires returnValue to be set.
		  	return e.returnValue = 'Wait a time for saving...';
  		}
  	}

  	getLenth(text){
  		const textArr = text.split(/\r?\n/);
  		if( ! this.textDemo ){
  			this.textDemo = document.createElement('div');
  			this.textDemo.style.fontSize = 13;
  			this.textDemo.style.left = '-9999px';
  			this.textDemo.style.visibility = 'hidden';
  			this.textDemo.style.position = 'absolute';

  			document.body.appendChild(this.textDemo);
  		}
  		this.textDemo.innerHTML = text.replace(/\r?\n/, '<br/>');

  		return Math.max(129, this.textDemo.offsetWidth );
  		// let len = 129;
  		// for(let _text of textArr ){
  		// 	len = Math.max(len, (_text.length * 6) + 50)
  		// }
  		// return len;
  	}

  	updateLayer(_layer, group, _diff){
  		const pos = group.position();
  		// change position group
  		group.position({
  			x: pos.x - _diff/2,
  			y: pos.y
  		});

  		for( let child of group.getChildren() ){
  			if( child.attrs.name === 'line' ){
  				child.points( [0, 0, child.attrs.points[2] + _diff, 0] );
  			}else if( child.attrs.name === 'line3' ){
  				const pos = child.position();
  				child.position({
  					x: pos.x + _diff/2,
  					y: pos.y
  				});
  			}
  			// else if( child.attrs.name === 'close-button'){
  			// 	const pos = child.position();
  			// 	child.position({
  			// 		x: pos.x + _diff,
  			// 		y: pos.y
  			// 	});
  			// // is text/rect
  			// }
  			else{
  				child.width(child.width() + _diff);
  			}
  		}

  		_layer.draw();
  	}

  	drawLayer(layerObj, id){
  		if( ! layerObj ){
  			return;
  		}

  		const _this = this;
  		// let stageObj = this.state.stages[this.state.currentStage];
  		// let _this = this;
  		const hTextBox = 40;
		this.wPinDrop = 50;

		id = this.state.currentStage +''+ id;
  		// var stage = poiEditor;
		let layer = new Konva.Layer({
			x:layerObj.x,
  			y:layerObj.y,
  			id: 'layer_'+ id,
			draggable:true,
			dragBoundFunc: function(pos){
  				layerObj.x = pos.x * 1/_this.scale;
  				layerObj.y = pos.y * 1/_this.scale;

  				// trigger autosave
  				_this.triggerAutoSave('drawLayer');
  				return pos;
  			}
		});

		// let _timeout_id = 0,
			// _timout_hide_close_btn = 0,
			// hold_time = 1000;

		// layer.setZIndex(100);

		if(! layerObj.text_len){
			layerObj.text_len = this.getLenth(layerObj.text);
			// layerObj.text_len = layerObj.text_len > 150 ? layerObj.text_len : 150;
		}


		let event = {
			'layerDown':'mousedown',
			'layerUp': 'mouseup',
			// 'text': 'dblclick',
			'close': 'click'
		};

		if( isMobile ){
			event.layerDown = 'touchstart';
			// event.text = 'dbltap';
			event.layerUp = 'touchend';
			event.close = 'tap';
		}

		let _line3 = new Konva.Line({
			y: 35,
			x: layerObj.text_len/2-1,
			points: [0, 0, 0, layerObj.distance_marker + 20],
			stroke: '#aed2e1',
			strokeWidth: 2,
			name: 'line3'
		});

		let group = new Konva.Group({
  			// y:layerObj.y,
  			draggable: true,
  			id: 'group_'+id,
  			dragBoundFunc: function(pos){
  				layerObj.distance_marker += ( this.getAbsolutePosition().y - pos.y ) * 1/_this.scale;
  				_line3.points([0, 0, 0, layerObj.distance_marker + 20]);
  				// trigger autosave
  				_this.triggerAutoSave('group');
  				return {
				    x: this.getAbsolutePosition().x,
				    y: pos.y
				};
  			}
  		});

  		// add line3
	    group.add( _line3 );

		
		// rect of text
		let rect_text = new Konva.Rect({
	        width: layerObj.text_len,
	        height: hTextBox,
	        fill:'#37a2cf'
	        // fillLinearGradientStartPoint: { x: 0, y: 0 },
	        // fillLinearGradientEndPoint: { x: layerObj.text_len/2, y: layerObj.text_len/2 },
	        // fillLinearGradientColorStops: [0, '#1086ce', 1, '#063271']
	    });
	    group.add(rect_text);

	    // add line
	    group.add( new Konva.Line({
		  y: hTextBox-4,
		  points: [0, 0, layerObj.text_len, 0],
		  stroke: '#2081b0',
		  strokeWidth: 1,
		  name: 'line'
		}) );

		// add line2
	    group.add( new Konva.Line({
		  y: hTextBox-1,
		  points: [0, 0, layerObj.text_len, 0],
		  stroke: '#baddec',
		  strokeWidth: 1,
		  name: 'line'
		}) );


	    let text = new Konva.Text({
	        fontSize: 13,
	        text: layerObj.text,
	        fill: '#fff',
	        id: 'text_'+id,
	        lineHeight:1.1,
	        width: layerObj.text_len,
	        height: hTextBox,
	        padding: 5,
	        verticalAlign: 'middle',
	        align: 'center',
	        name: 'text'
	    });

	    // add text
	    group.add(text);


	    // var _close_btn;

		// layer.on(event.layerDown,function(){
		// 	let _layerT = this;
		// 	_timeout_id = setTimeout(function(){
		// 		if( Konva.isDragging() ) return;

		// 		_close_btn = _layerT.findOne('.close-button');
		// 		if( _close_btn ){
		// 			_close_btn.show();
		// 		}else{
		// 			let imageObj = new window.Image();
		// 			// imageObj.setAttribute('crossOrigin', 'anonymous');
		// 		    imageObj.onload = function() {
		// 				let _close_btn = new Konva.Image({
		// 					width:14,
		// 					height:14,
		// 					x:layerObj.text_len - 7,
		// 					y:-7,
		// 					image: imageObj,
		// 					name: 'close-button'
		// 				});

		// 				_close_btn.on(event.close,function(){
		// 					layer.remove();

		// 					delete stageObj.layers[id];
		// 				});

		// 				// add the shape to the layer
		// 				group.add(_close_btn);
		// 				group.draw();
		// 		    };
		// 		    imageObj.src = closeButton;
		// 		}
		// 	}, hold_time);
		// }).on(event.layerUp,function(){
		// 	let _layerT = this;
		// 	if( _timeout_id ){
		// 		clearTimeout(_timeout_id);
		// 	}

		// 	_close_btn = _layerT.findOne('.close-button');
		// 	if( _close_btn ){
		// 		setTimeout(function(){
		// 			_close_btn.hide();
		// 			_layerT.draw();
		// 		},3000);
		// 	}
		// });


	    // layer.add(group);

	    // var _this = this;
	    if( false ){
	    	text.on(event.text, () => {
	            // create textarea over canvas with absolute position
	            // first we need to find its positon
	            let textPosition = text.getAbsolutePosition();
	            let stageBox = poiEditor.getContainer().getBoundingClientRect();

	            const scrollTop = (window.scrollY !== undefined) ? window.scrollY : document.body.scrollTop;
	            let areaPosition = {
	                x: textPosition.x + stageBox.left,
	                y: textPosition.y + stageBox.top + scrollTop
	            };

	            // create textarea and style it
	            let textarea = document.createElement('textarea');
	            document.body.appendChild(textarea);

	            textarea.value = text.text();
	            textarea.style.position = 'absolute';
	            textarea.style.top = areaPosition.y + 'px';
	            textarea.style.left = areaPosition.x + 'px';
	            textarea.style.width = text.width() + 'px';
	            textarea.style.textAlign = 'center';

	            textarea.focus();


	            textarea.addEventListener('keydown', function (e) {
	                // hide on enter
	                if (! e.shiftKey && e.keyCode === 13) {
	                    text.text(textarea.value);
	                    layerObj.text = textarea.value;
	                    let _diff = layerObj.text_len;
	                    layerObj.text_len = _this.getLenth(layerObj.text);
	                    _diff = layerObj.text_len - _diff;

	                    layerObj.x = layerObj.x - _diff/2;

	                    // update width
	                    _this.updateLayer(layer, group, _diff);

	                    // layer.draw();
	                    document.body.removeChild(textarea);

	                    // trigger autosave
	                    _this.triggerAutoSave('textarea');
	                }
	            });
	        });
	    }

        let imageObj = new window.Image();
	    imageObj.onload = function() {
			// let _close_btn = new Konva.Image({
			// 	width:14,
			// 	height:14,
			// 	x:layerObj.text_len - 7,
			// 	y:-7,
			// 	image: imageObj,
			// 	name: 'close-button'
			// });

			// _close_btn.on(event.close,function(){
			// 	layer.remove();

			// 	delete stageObj.layers[id];
			// });

			// // add the shape to the layer
			// group.add(_close_btn);
			
			imageObj = new window.Image();
		    let image;
		    imageObj.onload = function() {
				image = new Konva.Image({
					x: layerObj.text_len/2 - _this.wPinDrop/2,
					y: layerObj.distance_marker + hTextBox,
					image: imageObj,
					name: 'pin'
				});

				// add the shape to the layer
				layer.add(image);

				layer.add(group);

				// add the layer to the stage
				poiEditor.add(layer);
		    };
		    imageObj.src = Pindrop;
	    };
	    imageObj.src = closeButton;
  	}

  	componentDidUpdate(){
  		if( this.updatedStage ){
  			return;
  		}

  		// update
  		this.componentDidMount();
  	}

  	addNewPindrop(){
  		const layers = this.state.stages[this.state.currentStage].layers.slice();
  		const newLayer = {
			'text': "Sample\nText",
			'x': 0,
			'y': 0,
			'distance_marker': 50
		};
  		this.state.stages[this.state.currentStage].layers = layers.concat([newLayer]);

		this.drawLayer(newLayer, this.state.stages[this.state.currentStage].layers.length - 1);
		// this.state.stages[this.state.currentStage] = layers;
  		this.setState( (state) => ({
  			currentStage: this.state.currentStage,
  			stages: this.state.stages,
  			size: this.state.size
  		}) );

  		// updated stage
  		this.updatedStage = true;
  	}

  	dropPin(e){
  		const _rect = document.getElementById('poi-editor').getBoundingClientRect();
  		let _y = e.clientY - _rect.y;
  		let _x = e.clientX - _rect.x;

  		const hTextBox = 40;
  		const text = 'Sample\nText';
  		const hLayer = ( 125 ) * this.scale; // height pin drop
  		let wLayer = this.getLenth(text) * this.scale;
  		let _continue = true;
  		if( _y  > poiEditor.height() * this.scale ){
  			_y = poiEditor.height() - hLayer;
  			_continue = false;
  		}else if( _y > hLayer ){
  			_y = _y - hLayer;
  		}else if( _y > hLayer/2 ){
  			_y = _y - hLayer/2;
  		}

  		if( _x > poiEditor.width() * this.scale ){
  			_x = poiEditor.width() - wLayer/2;
  			_continue = false;
  		}else if( _x > wLayer/2 ){
  			_x = _x - wLayer/2;
  		}

  		if( _continue ){
  			const newLayer = {
				'text': text,
				'x': _x/this.scale,
				'y': _y/this.scale,
				'distance_marker': 50
			};

			const layers = this.state.stages[this.state.currentStage].layers.slice();

	  		this.state.stages[this.state.currentStage].layers = layers.concat([newLayer]);

			// this.drawLayer(newLayer, this.state.stages[this.state.currentStage].layers.length - 1);

			// const _box_id = "box_"+ ("0"+(this.state.stages[this.state.currentStage].layers.length)).slice(-2);
			// const _element = document.getElementById(_box_id);
			// _element.classList.add('added');
			// _element.getElementsByTagName('textarea')[0].removeAttribute("disabled");

			this.setState( (state) => ({
	  			currentStage: this.state.currentStage,
	  			stages: this.state.stages,
	  			size: this.state.size
	  		}) );
			// this.state.stages[this.state.currentStage] = layers;
	  		// this.setState( (state) => ({
	  		// 	currentStage: this.state.currentStage,
	  		// 	stages: this.state.stages
	  		// }) );

	  		// // updated stage
	  		// this.updatedStage = true;

	  		// trigger auto save
	  		this.triggerAutoSave('dropPin');

  		}
  		
  		e.preventDefault();

  	}

  	onStartIos(e, data){
  		this.rectPos = document.getElementById('poi-editor').getBoundingClientRect();
  	}

  	dropPinIos(e, data){
  		const _rect = this.rectPos;
  		const _point_pos = data.node.getBoundingClientRect();
  		
  		let _y = _point_pos.y - _rect.y;
  		let _x = _point_pos.x - _rect.x;

  		const hTextBox = 40;
  		const text = 'Sample\nText';
  		const hLayer = 115 * this.scale; // height pin drop
  		let wLayer = this.getLenth(text) * this.scale;
  		let _continue = true;
  		if( _y  > poiEditor.height() * this.scale ){
  			_y = poiEditor.height() - hLayer;
  			_continue = false;
  		}else{
  			_y = _y - hLayer;
  		}

  		if( _x > poiEditor.width() * this.scale ){
  			_x = poiEditor.width() - wLayer/2;

  			_continue = false;
  		}else if( _x > wLayer/2 ){
  			_x = _x - wLayer/2;
  		}

  		if( _continue ){
  			const newLayer = {
				'text': text,
				'x': _x/this.scale,
				'y': _y/this.scale,
				'distance_marker': 50
			};

			const layers = this.state.stages[this.state.currentStage].layers.slice();

	  		this.state.stages[this.state.currentStage].layers = layers.concat([newLayer]);

			// this.drawLayer(newLayer, this.state.stages[this.state.currentStage].layers.length - 1);

			// this.setState({value: this.state.value, isEditAble: true});
			// this.state.stages[this.state.currentStage] = layers;
			// const _box_id = "box_"+ ("0"+(this.state.stages[this.state.currentStage].layers.length)).slice(-2);
			// const _element = document.getElementById(_box_id);
			// _element.classList.add('added');
			// _element.getElementsByTagName('textarea')[0].removeAttribute("disabled");

	  		this.setState( (state) => ({
	  			currentStage: this.state.currentStage,
	  			stages: this.state.stages,
	  			size:this.state.size
	  		}) );

	  		// // updated stage
	  		// this.updatedStage = true;

	  		// trigger auto save
	  		this.triggerAutoSave('dropPin');
  		}
  		

  		e.preventDefault();

  	}

  	dropOver(e){
  		e.preventDefault();
  		return true;
  	}

  	handleDragEnter(evt) {
        evt.preventDefault();
        return true;
    }

  	poiSlider(i){
  		if( this.isChanging ){
  			const _this = this;
  			const bodyClass = document.body.classList;

  			bodyClass.add('loading');
  			this.save(()=>{
  				_this.setState( (state) => ({
		  			stages: state.stages,
					currentStage: state.currentStage + i,
					size: _this.state.size
		  		}));

		  		bodyClass.remove('loading');
  			});
  		}else{
  			this.wrapClass.add('loading');
  			this.setState( (state) => ({
	  			stages: state.stages,
				currentStage: state.currentStage + i,
				size: this.state.size
	  		}));
  		}
  	}

  	save(cb){
  		// add loading
  		this.wrapClass.add('saving');

  		if( this.wp && this.post_id ){
  			// clear autosave
	  		if( this.autoSave ){
	  			clearTimeout(this.autoSave);
	  			this.autoSave = 0;
	  		}

	  		if( this.isChanging ){
	  			const _this = this;

	  			if( _this.scale !== 1 ){
  					poiEditor.scale({
						x: 1,
						y: 1
					});
					poiEditor.draw();
  				}

  				const img = poiEditor.toDataURL({
	  				mimeType: 'image/jpeg'
	  			});


	  			if( img ){
	  				_this.wp.posts().id(_this.post_id).update({
		  				'editor_settings': _this.state.stages,
		  				'base64_imgs': {
		  					'id': _this.state.currentStage,
		  					'img': img
		  				}
		  			}).then(function(response){
		  				// if( response.id ){
		  				// 	_this.saveButton.classList.remove('active');
		  				// }

		  				if( typeof cb === 'function' ){
		  					setTimeout(cb, 100);
		  				}else{
		  					_this.wrapClass.remove('saving');
		  				}
		  				// update changing
		  				_this.isChanging = false;

		  				if( _this.scale !== 1 ){
		  					poiEditor.scale({
								x: _this.scale,
								y: _this.scale
							});
							poiEditor.draw();
		  				}
		  			});
	  			}else{
	  				_this.wrapClass.remove('saving');
		  			_this.isChanging = false;
	  			}

		  		return true;
	  		}
  		}
  	}

  	order(){
  		// trigger save
  		if( this.wp && this.post_id ){
  			const bodyClass = document.body.classList;

  			bodyClass.add('loading');
  			const _this = this;
  			if( this.isChanging ){
  				this.save(() => {
	  				PostData.sendEmail({
						'post_id': _this.post_id,
						'token': this.token
					}).then(function(res){
						_this.wrapClass.remove('loading');
						bodyClass.remove('loading');
						alert(res.message);
					});
	  			});
  			}else{

  				PostData.sendEmail({
					'post_id': _this.post_id,
					'token': this.token
				}).then(function(res){
					_this.wrapClass.remove('loading');
					bodyClass.remove('loading');
					alert(res.message);
				});
  			}

  			return true;
  		}
  	}

  	changeBackground(e){
  		const _this = this;
  		if( this.wp && e.target.files[0] ){
  			this.classBody.add('loading');
  			this.wp.media().file(e.target.files[0]).create().then(function(response){
  				const source_url = ('large' in response.media_details.sizes) ? response.media_details.sizes.pindrop_image.source_url : response.source_url;
  				_this.state.stages[_this.state.currentStage].image = source_url;

  				_this.triggerAutoSave('change bg')

  				_this.setState({
  					currentStage: _this.state.currentStage,
  					stages: _this.state.stages,
  					size: _this.state.size
  				});

  				// trigger save
  				setTimeout(function(){
  					_this.classBody.remove('loading');
  				}, 200);
  			});
  		}
  	}

  	insertBackground(e){
  		if( this.wp && e.target.files[0] ){
  			const that = this;

  			this.wrapClass.add('loading');
  			this.wp.media().file(e.target.files[0]).create().then(function(response){
  				that.state.currentStage = that.state.size;
  				that.state.stages[that.state.currentStage] = {};
  				that.state.stages[that.state.currentStage].layers = [];
				const source_url = ('large' in response.media_details.sizes) ? response.media_details.sizes.pindrop_image.source_url : response.source_url;

  				that.state.stages[that.state.currentStage].image = source_url;

  				that.triggerAutoSave('inserted bg');

  				that.setState({
  					currentStage: that.state.currentStage,
  					stages: that.state.stages,
  					size: that.state.size + 1
  				});

  				// trigger save
  				// setTimeout(function(){
  				// 	that.classBody.remove('loading');
  				// }, 200);
  			});
  		}
  	}

  	deleteBackground(){
  		// delete current stage
  		// delete this.state.stages[this.state.currentStage];
  		if( this.wp ){
  			this.wrapClass.add('loading');

  			if( this.state.size === 1 ){
  				this.deleteProject();
  			}else{
  				let newStages = {};
		  		let counter = 0;
		  		for(let i in this.state.stages){
		  			if( i != this.state.currentStage){
		  				newStages[counter] = this.state.stages[i];
		  				counter++;
		  			}
		  		}

		  		const that = this;
		  		if( newStages ){
		  			this.wp.posts().id(that.post_id).update({
		  				'editor_settings': newStages,
		  				'delete_a_pin': {
		  					'id': that.state.currentStage
		  				}
		  			}).then(function(response){
		  				if( response.id ){
		  					// if( ! that.saveButton ){
					  		// 	that.saveButton = document.getElementById('poi-save');
					  		// }

		  					// that.saveButton.classList.remove('active');

		  					// update changing
		  					that.isChanging = false;

		  					that.state.stages = newStages;
		  					// update current stage
		  					that.state.currentStage = that.state.currentStage > 0 ? that.state.currentStage - 1 : 0;
		  					that.setState({
			  					currentStage: that.state.currentStage,
			  					stages: that.state.stages,
			  					size: that.state.size - 1
			  				});
		  				}

		  				setTimeout(function(){
		  					that.classBody.remove('loading');
		  				}, 200);
		  			});
		  		}
  			}
  		}
  	}


  	deleteProject(){
		if(this.post_id && this.wp ){
			this.wp.posts().id(this.post_id).delete().then(function(res){
				if( res.id ){
					window.location.href = setting.home_url;
				}
			});
		}
	}

  	triggerAutoSave(where){
  		this.isChanging = true;
  		if( this.autoSave ) return;
  		// if( ! this.saveButton ){
  		// 	this.saveButton = document.getElementById('poi-save');
  		// }
  		// this.saveButton.classList.add('active');
  		this.autoSave = setTimeout(this.save, setting.autosave_time);
  	}

  	onChangeText(text, id){
  		id = parseInt(id) - 1;

  		const layerObj = this.state.stages[this.state.currentStage].layers[id];



  		id = this.state.currentStage +''+ id;

  		const layer = poiEditor.findOne('#layer_'+id);

  		const group = layer.findOne('#group_'+id);
  		const textLayer = group.findOne('#text_'+id);

  		textLayer.text(text);
  		layerObj.text = text;
        let _diff = layerObj.text_len;
        layerObj.text_len = this.getLenth(layerObj.text);
        _diff = layerObj.text_len - _diff;

        layerObj.x = layerObj.x - _diff/2;

        // update width
        this.updateLayer(layer, group, _diff);



        layer.draw();

    //     this.setState( (state) => ({
  		// 	currentStage: this.state.currentStage,
  		// 	stages: this.state.stages,
  		// 	size: this.state.size
  		// }) );

        // trigger autosave
        this.triggerAutoSave('textarea');
  	}

  	removeLayer(id){
  		id = parseInt(id) - 1;  		

  		const layerObj = this.state.stages[this.state.currentStage].layers;

  		const layer = poiEditor.findOne('#layer_'+ this.state.currentStage + id);

  		if( layer ){
  			layer.remove();
	  		// delete from list
	  		delete layerObj[id];

	  		let newLayer = [];
	  		if( layerObj ){
		  		Object.keys(layerObj).sort().forEach(function(key){
		  			newLayer.push(layerObj[key]);
		  		});
	  		}

	  		this.state.stages[this.state.currentStage].layers = newLayer;

	  		this.setState( (state) => ({
	  			currentStage: this.state.currentStage,
	  			stages: this.state.stages,
	  			size: this.state.size
	  		}) );
	  		
	  		this.triggerAutoSave('removeLayer');
  		}else{
  			document.body.classList.remove('loading');
  		}
  	}

  	sendLinkToClient(){
  		const clientEmails = window.prompt('Enter the client email(s), multiple email separated by comma (,):');
  		if( clientEmails ){
  			const bodyClass = document.body.classList;
  			bodyClass.add('loading');
  			const that = this;
  			PostData.sendLink({
				'email': clientEmails,
				'post_id': that.post_id
			}).then(function(res){
				that.wrapClass.remove('loading');
				bodyClass.remove('loading');
				alert(res.message);
			});
  		}
  	}


  	closeVideo(){
  		document.getElementById('video-wrap').style.display = 'none';

  		localStorage.setItem("watched-video", 1);
  	}

	render() {

		if( ! this.classBody ){
			this.classBody = document.getElementsByTagName("BODY")[0].classList;
		}
		if( this.state.loading ){
			this.classBody.add('loading');
			return "";
		}
		// remove loading
		this.classBody.remove('loading');

		if( ! this.state.stages ){
			return "Empty";
		}

	 	let prevButton;
	 	if( this.state.currentStage > 0 ){
	 		prevButton = <span><span data-tip="Previous Image" className="button" onClick={()=> this.poiSlider(-1)} id="poi-prev">Prev</span><ReactTooltip type="light" delayHide={100} place="bottom" effect="solid" delayShow={100} /></span>;
	 	}else{
	 		prevButton = <span className="button disabled" id="poi-prev">Prev</span>;
	 	}

	 	let nextButton;
	 	if( this.state.currentStage < this.state.size - 1 ){
	 		nextButton = <span><span data-tip="Next Image" className="button" onClick={()=> this.poiSlider(1)} id="poi-next">Next</span><ReactTooltip type="light" delayHide={100} place="bottom" effect="solid" delayShow={100} /></span>;
	 	}else{
	 		nextButton = <span className="button disabled" id="poi-next">Next</span>;
	 	}
	 	let listBoxes = [];
	 	const layers = this.state.stages[this.state.currentStage].layers;
	 	for(var i = 0; i<10; i++){
	 		if( i in layers ){
	 			listBoxes.push(<BoxText new={true} now={(new Date()).getTime()} currentStage={this.state.currentStage} key={i} id={('0' + (i+1)).slice(-2)} text={(layers[i]) ? layers[i].text : ''} editAble={true} dropPinIos={this.dropPinIos}  onStartIos={this.onStartIos} onRemoveLayer={this.removeLayer} onChangeText={this.onChangeText} />);
	 		}else{
	 			listBoxes.push(<BoxText new={true} now={(new Date()).getTime()} currentStage={this.state.currentStage} key={i} id={('0' + (i+1)).slice(-2)} text="" editAble={false} dropPinIos={this.dropPinIos}  onStartIos={this.onStartIos} onRemoveLayer={this.removeLayer} onChangeText={this.onChangeText} />);
	 		}
	 		// this.state.stages[this.state.currentStage].layers.map(function(box, idex){
		 	// 	return (<BoxText key={idex} id={('0' + (idex+1)).slice(-2)} text={box.text} />)
		 	// })
	 	}

	 	let listButton = [];
	 	const isAdmin = sessionStorage.getItem('edtkey');
	 	let video = [];
	 	if( isAdmin ){
	 		listButton.push(<li key={1} data-tip="Add Image" className="ovhid"><span data-delay-hide='500' className="button" id="poi-insert-bg"><input type="file" onChange={this.insertBackground} name="pic" accept="image/*" /></span></li>);
		 	listButton.push(<li key={2} data-tip="Change Image" className="ovhid"><span className="button" id="poi-edit-bg"><input type="file" onChange={this.changeBackground} name="pic1" accept="image/*" /></span></li>);
		 	listButton.push(<li key={3} data-tip="Delete Image"><span className="button" id="poi-delete-bg" onClick={this.deleteBackground}>x</span></li>);
	 	}else if( ! localStorage.getItem("watched-video") ){
	 		video.push(<div id="video-wrap"><div className="inner"><video poster={`${setting.home_url}/video-poster.jpg`} src={`${setting.home_url}/instruktioner.mp4`} controls /></div><a onClick={this.closeVideo}>x</a></div>);
	 	}

		return (
			<div id="poi-editor-wrapper" className="poi-editor-wrapperv2 clearfix">
				<div id="poi-wrapper" onTouchMove={this.onTouchMove}>
					<div id="poi-editor" onDragEnter={this.handleDragEnter} onDragOver={this.dropOver} onDrop={(node)=>this.dropPin(node)}>

					</div>
					<div id="poi-sidebar">
						<form>
							<ul className="list-poi-boxes">
								{listButton}
								<li id="order">
									<span className="button" onClick={isAdmin ? this.sendLinkToClient : this.order}>Bestil</span>
								</li>
								<li id="photo-slider" className="clearfix">
									<span className="photo-text">Photo {this.state.currentStage + 1}/{this.state.size}</span> {prevButton} {nextButton}
								</li>
							</ul>
							<ReactTooltip type="light" delayHide={100} place="bottom" effect="solid" delayShow={100} />
						</form>
					</div>
				</div>
				<div id="poi-right-sidebar">
					<form>
						{listBoxes}
					</form>
				</div>
				{video}
			</div>
		);
	}
}

export default Project;