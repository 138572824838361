import React from "react";
// import Project from './project';
import { Redirect, Link } from "react-router-dom";
import setting from './setting';
// import createBrowserHistory from "history/createBrowserHistory";
import WPAPI from 'wpapi';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
class Home extends React.Component {
	constructor(props){
		super(props);
		// const customHistory = createBrowserHistory();
		this.state = {
			posts: []
		};


		this.addNewProject = this.addNewProject.bind(this);
		this.deleteProject = this.deleteProject.bind(this);

	}

	componentDidMount(){
		this.wp = new WPAPI({ endpoint: setting.api_url });
		var _this = this;
		const author = parseInt(sessionStorage.getItem('author'));
		this.wp.posts().author(author).perPage(100).get().then(function(res){
			_this.setState({
				posts: res
			})
		});
	}

	deleteProject(e,id){
		id = parseInt(id);
		const parent = e.target.parentNode;
		const _this = this;
		if( ! this.rootListProject ){
			this.rootListProject = parent.parentNode;
		}
		if(id && Number.isInteger(id) && this.wp ){
			const token = sessionStorage.getItem('edtkey');
			const _this = this;
			this.wp.setHeaders('Authorization', 'Bearer '+ token);
			this.wp.posts().id(id).delete().then(function(res){
				if( res.id ){
					_this.rootListProject.removeChild(parent);
				}
			});
		}
	}

	addNewProject(){
		this.props.history.push('/new-project');
	}

	render(){
		const _this = this;
		const token = sessionStorage.getItem('edtkey');
		const ListProject = this.state.posts.map(function(post, id){
			if( post.editor_settings && post.editor_settings.length ){
				return (
					<li key={id}><Link to={{pathname:`/project/${post.id}`}}>{post.title.rendered}</Link> <button className="delete" onClick={(node)=>_this.deleteProject(node, post.id)}>x</button></li>
				);
			}
		});

		return(
			<div className="project-layout">
				<h1>Projects</h1>
				<ul className='list-projects'>
					{ListProject}

					<li><button className="main-btn" onClick={this.addNewProject}>New Project</button></li>
				</ul>


			</div>
		);
	}
}

export default Home;