import setting from './setting.js';
const PostData = {
	getToken: (userData) => {
		return new Promise((resolve,reject) => {
			fetch(setting.api_url + "jwt-auth/v1/token",{
		    	method: 'POST',
			      headers: new Headers({
			        'Content-Type': 'application/json'
			      }), 
			      body: JSON.stringify(userData)
		    })
		    .then((response) => response.json())
		    .then((responseJson) => {
		    	resolve(responseJson);
		    })
		    .catch((error) => {
		    	reject(error);
		    })
		});
	},

	sendEmail: (mailInfo) => {
		let token = sessionStorage.getItem('edtkey');
		if( ! token && mailInfo.token ){
			token = mailInfo.token;
			delete mailInfo.token;
		}
		return new Promise((resolve,reject) => {
			fetch(setting.api_url + 'contact/v1/send',{
		    	method: 'POST',
			    headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+ token
				}),
				body: JSON.stringify(mailInfo)
		    })
		    .then((response) => response.json())
		    .then((responseJson) => {
		    	resolve(responseJson);
		    })
		    .catch((error) => {
		    	reject(error);
		    })
		});
	},

	sendLink: (mailInfo) => {
		const token = sessionStorage.getItem('edtkey');
		mailInfo.token = token;
		return new Promise((resolve,reject) => {
			fetch(setting.api_url + 'contact/v1/send_link',{
		    	method: 'POST',
			    headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+ token
				}),
				body: JSON.stringify(mailInfo)
		    })
		    .then((response) => response.json())
		    .then((responseJson) => {
		    	resolve(responseJson);
		    })
		    .catch((error) => {
		    	reject(error);
		    })
		});
	},

	getUser: ()=>{
		const token = window.btoa(setting.user +':'+setting.pass);
		return new Promise((resolve,reject) => {
			fetch(setting.api_url + '/wp/v2/users/me',{
		    	method: 'POST',
			    headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+ token
				}),
				body: JSON.stringify({
					'first_name':'Hello'
				})
		    })
		    .then((response) => response.json())
		    .then((responseJson) => {
		    	console.log(responseJson);
		    	resolve(responseJson);
		    })
		    .catch((error) => {
		    	reject(error);
		    })
		});
	}
}

export default PostData;