// import React from "react";
// const myDomain = 'http://poieditor.anginao.com';
const myDomain = 'http://pindrop-tool.com';
// const myDomain = 'http://test.local';
const setting = {
	home_url: myDomain,
  api_url: myDomain + '/api/wp-json/',
  autosave_time: 20000
  // is_test: true
};

export default setting;