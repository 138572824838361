import React, { Component } from "react";
// import Konva from "konva";
import { render } from "react-dom";
import Logo from './images/logo.png';
// import closeButton from './images/close.png';
// import insertButton from './images/insert.png';
// import saveButton from './images/save.png';
import './styles/styles.scss';
import Home from './includes/home';
// import Login from './includes/login';
import Projects from './includes/projects';
import NewProject from './includes/new-project';
import PostData from './includes/post-data';
import setting from './includes/setting';
import fakeAuth from './includes/fake-auth';
import {osName} from 'react-device-detect';
// import { Stage, Layer, Text, Line, Image, Rect, Transformer } from "react-konva";
import { BrowserRouter as Router, Redirect, Route, Link } from "react-router-dom";
import WPAPI from 'wpapi';
function PrivateRoute({ component: Component, ...rest }) {
	return (
		<Route
		  {...rest}
		  render={props =>
		    fakeAuth.isAuthenticated ? (
		      <Component {...props} />
		    ) : (
		      <Redirect
		        to={{
		          pathname: "/login",
		          state: { from: props.location }
		        }}
		      />
		    )
		  }
		/>
	);
}

class Header extends React.Component{
	componentDidMount(){

		if( fakeAuth.isAuthenticated && this.props.location.pathname.indexOf('/project/') === -1 ){
			const wp = new WPAPI({ endpoint: setting.api_url });

			const token = sessionStorage.getItem('edtkey');
			wp.setHeaders('Authorization', 'Bearer '+ token);
			wp.users().me().get().then(function(res){
				if( res.id ){
					document.getElementById('project-title').innerHTML = res.name;
				}
			});
		}
	}
	render(){
		const token = sessionStorage.getItem('edtkey');
		let project_head = ( token && fakeAuth.isAuthenticated ) ? (
			<div className="single-header">
				<span id="project-title"></span>
				<a href="/"><span>BACK TO PROJECT OVERVIEW</span></a>
			</div>
		) : (
			<div className="single-header">
				<span id="project-title"></span>
			</div>
		);

		return(
			<div className="header">
				<div className="container clearfix">
					<span className="logo"><img src={Logo} alt="logo"/></span>
					{project_head}
				</div>
			</div>
		);
	}
}

class Login extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			redirectToReferrer: fakeAuth.isAuthenticated,
			auth: {
				username:'',
				password:''
			}
		};


		// handle event
		this.login = this.login.bind(this);

		this.onChangeAuth = this.onChangeAuth.bind(this);
	}

	login = (e) => {
		e.preventDefault();
		if( ! this.classBody ){
			this.classBody = document.body.classList;
		}

		this.classBody.add('loading');
		const _form = e.target;
		let _this = this;
		PostData.getToken(this.state.auth).then(function(response){
			if( response && response.token ){
				// this.wp.setHeaders('Authorization', 'Bearer '+ token);
				sessionStorage.setItem('edtkey', response.token);
				sessionStorage.setItem('author', response.user_id);
	    		fakeAuth.authenticate(() => {
			    	_this.setState({ redirectToReferrer: true });
			    });
			}else{
				if( response.message ){
					let _error = _form.querySelector('#error-login');
					if( ! _error ){
						_error = document.createElement('div');
						_error.setAttribute("id", "error-login");
						_form.appendChild(_error);
					}

					_error.innerHTML = response.message;
				}
			}

			_this.classBody.remove('loading');
		});
	}

	onChangeAuth(e){
		this.state.auth[e.target.name] = e.target.value;
	}

	render(){

		if( this.state.loading ){
			return 'Loading....';
		}
		let { from } = this.props.location.state || { from: { pathname: "/" } };

	    if (this.state.redirectToReferrer) return <Redirect to={from} />;

		return (
			<form className="form-login project-layout" onSubmit={this.login}>
				<h1>CLIENT LOGIN</h1>
		        <p>
		        	<input id="username" required={true} name="username" placeholder="username" onChange={this.onChangeAuth} type="text" />
		        </p>
		        <p>
		        	<input id="password" required={true} placeholder="password" name="password" onChange={this.onChangeAuth} type="password" />
		        </p>
		        <button className="main-btn">Login</button>
		    </form>
		);
	}
}

class POIEditor extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading:true
		}
	}

	componentWillMount(){
		const token = sessionStorage.getItem('edtkey');
		document.documentElement.classList.add(osName.replace(' ','-').toLowerCase());
		if( token ){
			const _this = this;
			
			fetch(setting.api_url +'JWT-AUTH/V1/TOKEN/VALIDATE',{
				method: "POST",
				headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+ token
				})
			})
			.then(function(result){
				return result.json();
			})
		    .then(function(res){
		    	if( res.data.status === 200 ){
		    		fakeAuth.authenticate(() => {
		    			_this.setState({
			    			loading:false
			    		});
		    		});
		    	}else{
		    		_this.setState({
		    			loading:false
		    		});
		    	}
		    });
		}else{
			this.setState({
    			loading:false
    		});

    		// update auth
    		if( window.location.href.indexOf('/project/') > -1 ){
    			fakeAuth.authenticate();
    		}
		}
	}

	render(){
		if( ! this.classBody ){
			this.classBody = document.body.classList;
		}
		if( this.state.loading ){
			this.classBody.add('loading');
			return "";
		}
		// remove loading
		this.classBody.remove('project');
		this.classBody.remove('loading');

		// const logout = fakeAuth.isAuthenticated ? <button id='logout' onClick={this.logout}>Logout</button> : '';
		// const AuthButton = withRouter(
		//   ({ history }) =>
		//     fakeAuth.isAuthenticated ? (
		//       <button id='logout' onClick={
		//       	() => {
		//       		sessionStorage.clear();
		//             fakeAuth.signout(() => history.push("/login"));
		//         }
		//       }>Logout</button>
		//     ) : ("")
		// );
		return (
			<Router>
			    <div className="wrapper">
					<Route component={Header} />
			    	<div className="container">
						<PrivateRoute exact path="/" component={Home} />
						<Route exact path="/login" component={Login} />
						<PrivateRoute exact path="/new-project" component={NewProject} />
						<Route path="/project" component={Projects} />
					</div>
			    </div>
			</Router>
		);
	}
}

// export default POIEditor;

render(<POIEditor />, document.getElementById("root"));
