import React, { Component } from "react";
import setting from './setting';

import WPAPI from 'wpapi';

class NewProject extends Component {
	constructor(props){
		super(props);

		this.params = {
			name:'',
			files:{}
		}

		this.state={
			no_file:0
		}

		// this.state ={
		// 	redirectToReferrer: false
		// }

		this.onSubmit = this.onSubmit.bind(this);
		this.changeName = this.changeName.bind(this);
		this.changeFiles = this.changeFiles.bind(this);

	}
	onSubmit(e){
		e.preventDefault();
		const bodyClass = document.getElementsByTagName("BODY")[0].classList;

		bodyClass.add('loading');
		// upload images
		if( this.params.files.length === 0 || this.params.name.length === 0 ){
			alert('Name or images empty');
			return;
		}else{
			const token = sessionStorage.getItem('edtkey');

			const _this = this;

			const wp = new WPAPI({ endpoint: setting.api_url });
			wp.setHeaders('Authorization', 'Bearer '+ token);


			wp.posts().create({
			    // "title" and "content" are the only required properties
			    title: _this.params.name,
			    content: _this.params.name,
			    // Post will be created as a draft by default if a specific "status"
			    // is not specified
			    status: 'publish'
			}).then(function( postRes ) {
			    let stages = [];

			    let count = 0;
				for(let i = 0; i < _this.params.files.length; i++){
					wp.media().file(_this.params.files[i]).create().then(function(response){
						stages.push({
							'image': response.source_url
						});
						count ++;
						if( count === _this.params.files.length ){
							wp.posts().id(postRes.id).update({
								'editor_settings': stages
							}).then(function(response){
								if(response.id){
									bodyClass.remove('loading');
									_this.props.history.push('/project/'+ response.id);
								}
							});
						}
					});
				}
			})
		}
	}
	changeName(e){
		this.params.name = e.target.value;
	}
	changeFiles(e){
		this.params.files = e.target.files;

		this.setState({
			no_file: e.target.files.length
		});
	}
	render(){
		document.body.classList.add('project');
		const no_file = this.state.no_file + (this.state.no_file > 1 ? ' Files' : ' File' );
		return (
			<div className="new-project project-layout">
				<h1>New Project</h1>
				<form onSubmit={this.onSubmit}>
					<p>
						<input required={true} type="text" placeholder="PROJECT NAME" onChange={this.changeName} />
					</p>
					<p>
						<label className="up-files">Choose Files<input type="file" required={true} onChange={this.changeFiles} name="imgs" accept="image/*" multiple /> (<span id="no-fiels">{no_file}</span>)</label>
					</p>
					<button className="main-btn" type="submit">Go to editor</button>
				</form>
			</div>
		);
	}
}

export default NewProject;