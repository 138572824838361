import React from "react";
import Project from './project';
import { Route } from "react-router-dom";

class Projects extends React.Component {
	componentWillMount(){
		if( this.props.location.pathname === '/project/' || this.props.location.pathname === 'project' ){
			this.props.history.push('/');
		}
	}
	render(){
		return (
			<Route path={`/project/:id`} component={Project} />
		);
	}
}

export default Projects;